




















import { Component, Vue } from 'vue-property-decorator';
import { StepInformer, StepEchanger, StepAction, StepAvis } from '@/components';
import { NavigationHelper } from '@projetlucie/lc-front-sdk-old';

@Component({
  components: { StepInformer, StepEchanger, StepAction, StepAvis }
})
export default class SalarieAidant extends Vue {
  public goBack() {
    NavigationHelper.goBack(this.$router);
  }
}
